<template>
    <div class="modal" v-if="showModal">
        <div class="modal__box" v-click-outside="closeModal">
            <div class="modal__box--header row__spacebetween">
                <h2 class="text__black m-0">Termingespräch</h2>
                <div @click="closeModal">
                    <CloseSvg />
                </div>
            </div>
            <div id="modalBody" class="modal__body scrollbar">
                <p class="text__primary">Art des Gesprächs</p>
                <div class="row__start">
                    <CheckBox
                        v-for="chatType in companyChatTypes"
                        :key="chatType.id"
                        classes="mr-25 mt-10"
                        :active="
                            chatType.id == selectedChatTypeId ? true : false
                        "
                        :text="chatType.name"
                        @click="changeSelectedChatType"
                        :id="chatType.id"
                    />
                </div>
                <p class="error__message mt-20 mb-20" v-if="errorMessage">
                    {{ errorMessage }}
                </p>
                <p class="text__primary mt-20">Vereinbare jetzt einen Termin</p>
                <h3 class="mb-20 mt-4p" v-if="companyContacts.length > 0">
                    Gesprächspartner*in auswählen
                </h3>

                <UserCheckBox
                    v-for="user in companyContacts"
                    :key="user.id"
                    classes="mb-30"
                    :active="user.id == selectedUserId ? true : false"
                    :user="user"
                    @click="changeSelectedUser"
                />
                <div v-if="contactAppointments.length > 0">
                    <p class="text__primary mt-30">Freie Termine</p>
                    <GeneralSelect
                        :options="contactAppointments"
                        placeholder="Tag auswählen"
                        :selectedItem="selectedDay"
                        classes="mt-15"
                        @change="setDay"
                    />

                    <GeneralSelect
                        v-if="allTimes.length > 0"
                        :options="allTimes"
                        placeholder="Uhrzeit auswählen"
                        :selectedItem="selectedTime"
                        :autoscrollDown="true"
                        classes="mt-15 mb-30"
                        @change="setTime"
                    />
                </div>
                <div class="modal__footer--responsive mt-20 row__spacebetween">
                    <div
                        class="button button--reverse mr-15"
                        @click="closeModal"
                    >
                        <p class="text__primary text__semibold">Abbrechen</p>
                    </div>
                    <div
                        class="button"
                        :class="
                            selectedTime.id == null || selectedChatTypeId == 0
                                ? 'button--grey'
                                : ''
                        "
                        @click="prepareAppointment"
                    >
                        <p class="text__white">Termin buchen</p>
                    </div>
                </div>
            </div>
            <div class="modal__footer row_end">
                <div class="button button--reverse mr-45" @click="closeModal">
                    <p class="text__primary text__semibold">Abbrechen</p>
                </div>
                <div
                    class="button"
                    :class="
                        selectedTime.id == null || selectedChatTypeId == 0
                            ? 'button--grey'
                            : ''
                    "
                    @click="prepareAppointment"
                >
                    <p class="text__white">Termin buchen</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
export default {
    name: "AppointmentModal",
    props: ["company_id", "showModal"],
    data() {
        return {
            selectedUserId: 0,
            selectedUser: {},
            selectedChatTypeId: 0,
            selectedDay: { id: null, name: "" },
            selectedTime: { id: null, name: "" },
            allTimes: [],
            contactAppointments: [],
            isLoading: false,
            errorMessage: "",
        };
    },
    methods: {
        ...mapActions(["bookAppointment"]),
        async prepareAppointment() {
            if (
                this.selectedUserId == 0 ||
                this.selectedChatTypeId == 0 ||
                this.isLoading
            ) {
                return;
            }
            this.isLoading = true;
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            target.innerHTML = "<div class='loading'></div>";
            let data = {
                contactId: this.selectedUserId,
                chatTypeId: this.selectedChatTypeId,
                day: this.selectedDay.name,
                time: this.selectedTime.name,
                companyId: this.company_id,
            };
            const statusCode = await this.bookAppointment(data);
            if (statusCode == 200) {
                setTimeout(() => {
                    this.closeModal();
                    this.$router.push({ name: "Calendar" });
                }, 500);
            } else if (statusCode == 201) {
                this.errorMessage =
                    "Dieser Termin wurde kurz vor ihrer Anfrage vergeben.";
                this.selectedTime = { id: null, name: "" };
                this.selectedDay = { id: null, name: "" };
                this.allTimes = [];
                this.selectedUserId = 0;
                this.contactAppointments = [];
            } else if (statusCode == 400) {
                this.errorMessage =
                    "Der von ihnen Ausgewählte Termin ist zu alt.";
                this.selectedTime = { id: null, name: "" };
                this.selectedDay = { id: null, name: "" };
                this.allTimes = [];
                this.selectedUserId = 0;
                this.contactAppointments = [];
            }
            target.innerHTML = "Termin buchen";
            this.isLoading = false;
        },
        setDay(option) {
            this.selectedTime = { id: null, name: "" };
            this.selectedDay = { id: option.id, name: option.name };
            this.allTimes = option.times;
        },
        setTime(option) {
            this.selectedTime = { id: option.id, name: option.name };
        },
        closeModal() {
            this.selectedTime = { id: null, name: "" };
            this.selectedDay = { id: null, name: "" };
            this.allTimes = [];
            this.selectedUserId = 0;
            this.selectedChatTypeId = 0;
            this.contactAppointments = [];
            this.$emit("close");
        },
        changeSelectedUser(user) {
            this.allTimes = [];
            this.contactAppointments = [];
            this.selectedUserId = user.id;
            this.selectedUser = user;
            this.contactAppointments =
                this.selectedChatTypeId == 4
                    ? user.fair_workshops
                    : user.workshops;
            this.selectedTime = { id: null, name: "" };
            this.selectedDay = { id: null, name: "" };
        },
        changeSelectedChatType(number) {
            this.selectedChatTypeId = number;
            if (this.selectedUserId != 0) {
                this.contactAppointments =
                    this.selectedChatTypeId == 4
                        ? this.selectedUser.fair_workshops
                        : this.selectedUser.workshops;
                this.selectedTime = { id: null, name: "" };
                this.selectedDay = { id: null, name: "" };
            }
        },
    },
    created() {},
    computed: {
        ...mapGetters(["companyChatTypes", "companyContacts"]),
    },
    mounted() {},
    directives: {
        ClickOutside,
    },
};
</script>