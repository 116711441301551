<template>
    <div id="app">
        <Navbar />

        <router-view v-if="!isWaiting" />
        <NavbarBottom />
        <NavbarUser v-if="$route.name != 'Chat'" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            isWaiting: true,
        };
    },
    methods: {
        ...mapActions(["checkUserLogin"]),
    },
    async created() {
        await this.$store.dispatch("setToken");
        await this.$store.dispatch("checkLogin");
        this.isWaiting = false;
    },
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
