import axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
window.base = process.env.VUE_APP_API_BASE;
window.baselink = window.base + "api/";

axios.defaults.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

window.Pusher = require("pusher-js");
window.Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG == "true" ? true : false;
window.PusherAppKey = process.env.VUE_APP_PUSHER_APP_KEY;

window.JitsiURL = process.env.VUE_APP_JITSI_URL;
window.GoogleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;

const svgFiles = require.context("./svg/", true, /\.vue$/i);
svgFiles
    .keys()
    .map((svgFileName) =>
        Vue.component(
            svgFileName.substring(2, svgFileName.length - 4),
            svgFiles(svgFileName).default
        )
    );

const componentFiles = require.context("./components/", true, /\.vue$/i);
componentFiles
    .keys()
    .map((componentFileName) =>
        Vue.component(
            componentFileName.substring(2, componentFileName.length - 4),
            componentFiles(componentFileName).default
        )
    );
const helperFiles = require.context("./helper/", true, /\.vue$/i);
helperFiles
    .keys()
    .map((componentFileName) =>
        Vue.component(
            componentFileName.substring(2, componentFileName.length - 4),
            helperFiles(componentFileName).default
        )
    );

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
