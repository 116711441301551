<template>
    <div class="gmapsBox" :class="show ? 'gmapsBox--show' : ''">
        <GmapMap
            :center="mapsCenterPoint"
            :zoom="12"
            map-type-id="roadmap"
            :options="{
                clickableIcons: false,
                streetViewControl: false,
                mapTypeControl: false,
            }"
        >
            <div v-for="company in availableCompanies" :key="company.id">
                <GmapMarker
                    :clickable="true"
                    :draggable="true"
                    v-for="(oneLatLong, index) in company.allLatLongs"
                    :key="index"
                >
                    <gmap-custom-marker
                        :marker="oneLatLong.latLong"
                        v-if="oneLatLong && oneLatLong.latLong"
                    >
                        <div
                            @click.stop="toggleLabel(company, index, true)"
                            class="pointer"
                            @mouseover="toggleLabelHoverEnter(company, index)"
                        >
                            <img
                                src="../assets/images/map-marker-alt.png"
                                style="width: 28px; height: 36px"
                            />
                        </div>
                    </gmap-custom-marker>
                    <gmap-custom-marker
                        :marker="company.allLatLongs[0].latLong"
                        :offsetX="-0"
                        :offsetY="-40"
                        v-if="company.showLabel"
                    >
                        <div
                            class="gmapsBox__marker--label"
                            @mouseleave="toggleLabelHoverLeave()"
                        >
                            <div
                                class="absoluteBox absoluteBox--top pointer p-5"
                                @click.stop="toggleLabel(company)"
                            >
                                <MapsXButton />
                            </div>
                            <h3
                                style="
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 90%;
                                "
                            >
                                {{ company.name }}
                            </h3>
                            <p
                                class="small text__grey mt-4p"
                                style="
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 90%;
                                "
                            >
                                {{ company.allAddresses[0] }}
                            </p>
                            <div class="absoluteBox absoluteBox--left">
                                <p
                                    class="text__primary mt-auto pointer"
                                    @click.stop="showCompany(company)"
                                >
                                    Zur Ausstellerseite
                                </p>
                            </div>
                            <div
                                v-if="company.is_fair_participant"
                                class="absoluteBox absoluteBox--right"
                            >
                                <ExhibitionSvgActive />
                            </div>
                        </div>
                    </gmap-custom-marker>
                </GmapMarker>
            </div>
        </GmapMap>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
    name: "HomeGoogleMaps",
    props: ["show"],
    components: {
        "gmap-custom-marker": GmapCustomMarker,
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
        toggleLabel(company, index) {
            if (index) {
                let selectedLatLong = company.allLatLongs.splice(index, 1)[0];
                let selectedAddress = company.allAddresses.splice(index, 1)[0];
                company.showLabel = false;
                company.allLatLongs.unshift(selectedLatLong);
                company.allAddresses.unshift(selectedAddress);
            }
            const newValue = !company.showLabel;
            this.availableCompanies.forEach((item) => {
                item.showLabel = false;
            });
            company.showLabel = newValue;
            if (newValue) {
                this.$store.commit("setSelectedCompany", company);
                this.$store.commit(
                    "setMapsCenterPoint",
                    company.allLatLongs[0].latLong
                );
            } else {
                this.$store.commit("setSelectedCompany", { id: null });
            }
        },
        toggleLabelHoverEnter(company) {
            const newValue = true;
            this.availableCompanies.forEach((item) => {
                item.showLabel = false;
            });
            company.showLabel = newValue;
            if (newValue) {
                this.$store.commit("setSelectedCompany", company);
            } else {
                this.$store.commit("setSelectedCompany", { id: null });
            }
        },
        toggleLabelHoverLeave() {
            this.availableCompanies.forEach((item) => {
                item.showLabel = false;
            });
            this.$store.commit("setSelectedCompany", { id: null });
        },
        showCompany(company) {
            this.$router.push({
                name: "Company",
                params: { id: company.id },
            });
        },
    },
    created() {},
    computed: {
        ...mapGetters([
            "availableCompanies",
            "selectedCompany",
            "mapsCenterPoint",
        ]),
    },
};
</script>