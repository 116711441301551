import Axios from "axios";

const state = {
  offerOptions: [],
  occupationOptions: [],
  fairs: null,
};

const getters = {
  offerOptions: (state) => state.offerOptions,
  occupationOptions: (state) => state.occupationOptions,
  fairs: (state) => state.fairs,
};
const mutations = {
  setOfferOptions: (state, bool) => {
    state.offerOptions = bool;
  },
  setOccupationOptions: (state, bool) => {
    state.occupationOptions = bool;
  },
  setFairs: (state, bool) => {
    state.fairs = bool;
  },
};
const actions = {
  getDashboardOptions({ commit }) {
    Axios.get(window.baselink + "getDashboardOptions").then(function (response) {
      if (response.status == 200) {
        commit("setOfferOptions", response.data.offers);
        commit("setOccupationOptions", response.data.occupations);
        commit("setFairs", response.data.fairs);
      }
    });
  },
};

export default {
  namespace: "options",
  state,
  getters,
  actions,
  mutations,
};