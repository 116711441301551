import Axios from "axios";
import router from "../../router";

const state = {
    myAppointments: [],
    /**
     * !old Function
     */
    //companyAppointments: [],
};

const getters = {
    myAppointments: (state) => state.myAppointments,
    companyAppointments: (state) => state.companyAppointments,
};
const mutations = {
    setMyAppointments: (state, bool) => {
        state.myAppointments = bool;
    },
    /**
     * !old Function
     */
    // setCompanyAppointments: (state, value) => {
    //   state.companyAppointments = value;
    // },
};
const actions = {
    async bookAppointment(
        { commit, dispatch },
        { candidateId, chatTypeId, day, time, contactId, companyId }
    ) {
        return await Axios.post(window.baselink + "bookAppointment", {
            candidateId,
            chatTypeId,
            day,
            time,
            contactId,
            companyId,
        })
            .then(function(response) {
                if (response.status == 201) {
                    /**
                     * !old Function
                     */
                    // commit("setCompanyAppointments", response.data.company.workshops);
                    // commit("setCompanyChatTypes", response.data.company.chat_types);
                    // commit("setCompanyContacts", response.data.company.contacts);

                    /**
                     * !new Function
                     *
                     * @var {[type]}
                     */
                    commit("setCompanyChatTypes", response.data.chatTypes);
                    commit("setCompanyContacts", response.data.contacts);
                }
                return response.status;
            })
            .catch(function(error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    router.push({
                        name: "Login",
                    });
                }
                return error.response.status;
            });
    },
    async openAppointmentBooking({ commit, dispatch }, { id }) {
        await Axios.get(window.baselink + "getCompanyAppointments/" + id)
            .then(function(response) {
                if (response.status == 200) {
                    /**
                     * !old Function
                     */
                    // commit("setCompanyAppointments", response.data.company.workshops);
                    // commit("setCompanyChatTypes", response.data.company.chat_types);
                    // commit("setCompanyContacts", response.data.company.contacts);

                    /**
                     * !new Function
                     *
                     * @var {[type]}
                     */
                    commit("setCompanyChatTypes", response.data.chatTypes);
                    commit("setCompanyContacts", response.data.contacts);
                }
            })
            .catch(function(error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    router.push({
                        name: "Login",
                    });
                }
            });
    },
    async getMyAppointments({ commit, dispatch }) {
        await Axios.get(window.baselink + "getMyAppointments")
            .then(function(response) {
                console.log(response);
                if (response.status == 200) {
                    commit("setMyAppointments", response.data.appointments);
                }
            })
            .catch(function(error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    router.push({
                        name: "Login",
                    });
                }
            });
    },
    async deleteAppointment({ dispatch }, { appointmentId }) {
        return await Axios.post(window.baselink + "deleteAppointment", {
            appointmentId,
        })
            .then(function(response) {
                if (response.status == 200) {
                    return true;
                }
            })
            .catch(function(error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    router.push({
                        name: "Login",
                    });
                }
                return false;
            });
    },
};

export default {
    namespace: "appointment",
    state,
    getters,
    actions,
    mutations,
};
