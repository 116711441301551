<template>
    <div
        class="select"
        :class="
            selectActive && classes
                ? classes + ' select--active'
                : classes
                ? classes
                : ''
        "
    >
        <div class="select__box">
            <div class="select__box--input" @click.stop="setActive">
                <input
                    class="select__input"
                    type="text"
                    v-model="selectedItem.name"
                    :placeholder="placeholder"
                    readonly
                />
                <SelectArrowSvg />
            </div>

            <div
                id="selectOptionsBox"
                class="select__options--box scrollbar"
                v-click-outside="removeActive"
            >
                <p
                    v-for="option in options"
                    :key="option.id + option.name"
                    @click="makeChangeEvent(option)"
                    class="ph-10 p--selectitem "
                    :class="selectedID == option.id ? 'text__primary' : ''"
                >
                    {{ option.name }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
export default {
    props: {
        options: {},
        placeholder: {},
        selectedItem: {},
        classes: {},
        autoscrollDown: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    data() {
        return {
            selectedID: null,
            selectActive: false,
        };
    },
    methods: {
        makeChangeEvent(option) {
            this.selectedID = option.id;
            this.$emit("change", option);
            this.selectActive = false;
        },
        setActive() {
            this.selectActive = !this.selectActive;
            if (this.autoscrollDown) {
                console.log("entered");
                setTimeout(() => {
                    var modalBody = document.getElementById("modalBody");
                    modalBody.scrollTop = modalBody.scrollHeight;
                }, 300);
            }
        },
        removeActive() {
            this.selectActive = false;
        },
    },
    directives: {
        ClickOutside,
    },
};
</script>
